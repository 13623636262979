import React from 'react';
import './Button.scss';
import { motion } from 'framer-motion';

interface ButtonProps {
  href?: string;
  rounded?: boolean;
  className?: string;
  children?: React.ReactNode;
  withShadow?: boolean;
  target?: '_blank' | '_self';
  onClick?: () => void;
}

const Button = ({ href, className, rounded, children, onClick, withShadow, target }: ButtonProps) => {
  if (href) {
    return (
      <motion.a
        initial={{
          scale: 1
        }}
        whileHover={{
          scale: 1.02
        }}
        whileTap={{
          scale: 0.98
        }}
        href={href}
        role="button"
        onClick={onClick}
        target={target || '_self'}
        rel={`${ target === '_blank'? 'noopener noreferrer' : undefined}`}
        className={`button espiral-btn is-uppercase 
          ${rounded ? 'is-rounded' : ''} 
          ${className ? className : ''}
          ${withShadow ? 'with-shadow' : ''}
          `}>
        {children}
      </motion.a>
    );
  }

  return (
    <motion.button
      initial={{
        scale: 1
      }}
      whileHover={{
        scale: 1.02
      }}
      whileTap={{
        scale: 0.98
      }}
      onClick={onClick}
      className={`button espiral-btn is-uppercase 
        ${rounded ? 'is-rounded' : ''} 
        ${className ? className : ''}
        ${withShadow ? 'with-shadow' : ''}
        `}>
      {children}
    </motion.button>
  );
}

export default Button;
Button.displayName = 'Button';
