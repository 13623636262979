import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/home';
import Dashboard from './sections/productos/dashboard/Dashboard';
import Mpos from './sections/productos/mpos/Mpos';
import Ecommerce from './sections/productos/ecommerce/Ecommerce';
import Tarjetas from './sections/productos/tarjetas/Tarjetas';
import Company from './sections/company/Company';
import Rates from './sections/rates/Rates';

const router = createBrowserRouter([
  {
    path: '*',
    element: <Home />,
  },
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/productos/dashboard',
    element: <Dashboard />
  },
  {
    path: '/productos/mpos',
    element: <Mpos />
  },
  {
    path: '/productos/ecommerce',
    element: <Ecommerce />
  },
  {
    path: '/productos/tarjetas',
    element: <Tarjetas />
  },
  {
    path: 'empresa',
    element: <Company />,
  },
  {
    path: 'tarifas',
    element: <Rates />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
