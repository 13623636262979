import React from 'react';
import './Card.scss';

interface CardProps {
  title: string;
  image: string;
  isExpandalbe?: boolean;
  contents: string[];
};

const Card = ({ image, contents, title, isExpandalbe }: CardProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpand = React.useCallback(() => {
    if (isExpandalbe) {
      setIsExpanded(!isExpanded);
    }
  }, [isExpanded, isExpandalbe]);

  return (
    <div className={`card espiral-card ${isExpandalbe ? 'expandable' : ''}`} onClick={toggleExpand}>
      <div className="card-content">
        <div className="content">
          <div className="img-container is-flex is-flex-direction-column is-align-items-center">
            <img src={image} alt="" />
          </div>
          <h3 className="is-size-6 has-text-centered">{title}</h3>
          <div className={`card-more-text ${(isExpanded || !isExpandalbe) ? 'expanded' : ''}`}>
            {contents.map((content, index) => (
              <p key={`card-${index}`} className="is-size-7">{content}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
Card.displayName = 'Card';