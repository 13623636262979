import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './locales/en';
import { es } from './locales/es';

const resources = {
  en: {
    translation: en,

  },
  es: {
    translation: es,
  },
};

export const supportedLanguages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'us'
  },
  {
    code: 'es',
    name: 'Español',
    country_code: 'mx'
  }
]

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es',
    fallbackLng: 'es',
    debug: process.env.NODE_ENV === 'development'
  });

export default i18n;
