import React from 'react';

interface Marign {
  top?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  right?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  bottom?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  left?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

interface Padding {
  top?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  right?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  bottom?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  left?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

interface SpacingProps {
  margin?: Marign;
  padding?: Padding;
  children?: React.ReactNode;
};

const getMarginClassName = (margin: Marign) => {
  let marginClassName = '';

  if (margin.top) {
    marginClassName += `mt-${margin.top} `;
  }

  if (margin.right) {
    marginClassName += `mr-${margin.right} `;
  }

  if (margin.bottom) {
    marginClassName += `mb-${margin.bottom} `;
  }

  if (margin.left) {
    marginClassName += `ml-${margin.left} `;
  }

  return marginClassName;
};

const getPaddingClassName = (padding: Padding) => {
  let paddingClassName = '';

  if (padding.top) {
    paddingClassName += `pt-${padding.top} `;
  }

  if (padding.right) {
    paddingClassName += `pr-${padding.right} `;
  }

  if (padding.bottom) {
    paddingClassName += `pb-${padding.bottom} `;
  }

  if (padding.left) {
    paddingClassName += `pl-${padding.left} `;
  }

  return paddingClassName;
};


const Spacing: React.FC<SpacingProps> = ({ children, margin, padding }) => {
  const marginClassName = margin ? getMarginClassName(margin) : '';
  const paddingClassName = padding ? getPaddingClassName(padding) : '';

  return (
    <div className={`${marginClassName} ${paddingClassName}`}>
      {children}
    </div>
  );
};

export default Spacing;
Spacing.displayName = 'Spacing';
