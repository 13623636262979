import React from 'react';

type TextElementContent = string | React.ReactNode

interface TextElementProps {
    content: TextElementContent[];
    heading?: string;
    subheading?: string;
    theme?: 'blue' | 'green' | 'purple' | 'yellow';
}

const getThemeClassName = (theme: string) => {
    switch (theme) {
        case 'blue':
            return 'has-light-blue';
        case 'green':
            return 'has-text-success';
        case 'purple':
            return 'has-purple';
        case 'yellow':
            return 'has-text-warning';
        default:
            return 'has-text-black-ter';
    }
};

const TextElement = ({ content, heading, subheading, theme }: TextElementProps) => {
    const themeClassName = getThemeClassName(theme || 'blue')

    return (
        <>
            {heading && <h1 className={`is-size-5 is-size-6-mobile has-text-weight-bold ${themeClassName}`}>{heading}</h1>}
            {subheading && <h2 className={`is-size-4 is-size-5-mobile has-text-weight-bold ${heading? 'has-text-black-ter mb-5' : themeClassName}`}>{subheading}</h2>}
            {content.map((text, index) => (
                <p key={`content-text-${index}`} className="has-text-grey is-size-5 is-size-6-mobile">{text}</p>
            ))}
        </>
    );
};

export default TextElement;
TextElement.displayName = 'TextElement';