import React from 'react';
import { Heading, Spacing } from '../../components';
import { useTranslation } from 'react-i18next';
import { tns } from 'tiny-slider';

const HomeClientes = () => {
    const { t } = useTranslation();

    React.useEffect(() => {
      const slider = tns({
        container: '.my-slider',
        items: 1,
        edgePadding: 20,
        gutter: 20,
        autoplay: true,
        nav: false,
        controls: false,
        autoplayButtonOutput: false,
        responsive: {
          640: {
            items: 2
          },
          700: {
            gutter: 30
          },
          900: {
            gutter: 40
          },
          1215: {
            items: 3
          }
        }
      });
  
      return () => {
        slider.destroy();
      };
    }, []);

    return (
      <div className="container">
        <Spacing margin={{ top: 6, bottom: 6 }}>
          <Heading>{t('homepage.community.title')}</Heading>
        </Spacing>
        <div className="columns">
          <div className="column is-10 is-offset-1 community-cards">
            <div className="my-slider pt-5 pb-5 is-size-6">
              <div>
                <div className="card">
                  <div className="card-content">
                    <div className="content has-text-centered">
                      <img className="mt-3" src="/assets/home/15persona.png" alt="" />
                      <p className="is-size-7 mt-5">{t('homepage.community.person1.text1')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card">
                  <div className="card-content">
                    <div className="content has-text-centered">
                      <img className="mt-3" src="/assets/home/16persona.png" alt="" />
                      <p className="is-size-7 mt-5">{t('homepage.community.person2.text1')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card">
                  <div className="card-content">
                    <div className="content has-text-centered">
                      <img className="mt-3" src="/assets/home/17persona.png" alt="" />
                      <p className="is-size-7 mt-5">{t('homepage.community.person3.text1')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card">
                  <div className="card-content">
                    <div className="content has-text-centered">
                      <img className="mt-3" src="/assets/home/18persona.png" alt="" />
                      <p className="is-size-7 mt-5">{t('homepage.community.person4.text1')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card">
                  <div className="card-content">
                    <div className="content has-text-centered">
                      <img className="mt-3" src="/assets/home/19persona.png" alt="" />
                      <p className="is-size-7 mt-5">{t('homepage.community.person5.text1')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default HomeClientes;
HomeClientes.displayName = 'HomeClientes';
