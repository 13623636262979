import React, { useCallback } from 'react';
import './Footer.scss';

import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../components';
import Heading from '../../components/Heading/Heading';
import { Link, NavLink } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <section className="section start-today-footer pb-0">
        <div className="container is-size-5 has-text-centered">
          <Heading weight="bold" color="light-blue">{t('footer.contactanos.header')}</Heading>
          <p className="is-size-4 is-size-5-mobile">{t('footer.contactanos.text1')}</p>
          <p className="has-text-weight-bold is-size-4 is-size-5-mobile">{t('footer.contactanos.text2')}</p>

          <div className="buttons is-centered are-medium my-6">
            <Button href={t('homepage.banner.button.url')} target="_blank" className="px-6 mx-5 my-3" withShadow>{t('footer.contactanos.button.contactUs')}</Button>
            <Button href="https://dash.espiralapp.com/es/register" className="px-6 my-3 mx-5" withShadow>{t('footer.contactanos.button.createAccount')}</Button>
          </div>
        </div>
      </section>
      <div className="clip-path-container">
        <section className="section clip-path" />
      </div>
      <section className="section footer-links">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <img src="/assets/footer/22Logoespiralapp-8.png" alt="" />
            </div>
            <div className="column is-three-fifths is-size-7 has-text-white">
              <div className="columns">
                <div className="column">
                  <h4 className="has-text-weight-bold">{t('footer.soluciones.header')}</h4>
                  <ul>
                    <li>
                      <NavLink className={(navData) => (navData.isActive ? 'active-footer-item' : '')} to="/productos/mpos" onClick={scrollToTop}>{t('footer.soluciones.item1')}</NavLink>
                    </li>
                    <li>
                      <NavLink className={(navData) => (navData.isActive ? 'active-footer-item' : '')} to="/productos/ecommerce" onClick={scrollToTop}>{t('footer.soluciones.item2')}</NavLink>
                    </li>
                    <li>
                      <NavLink className={(navData) => (navData.isActive ? 'active-footer-item' : '')} to="/productos/dashboard" onClick={scrollToTop}>{t('footer.soluciones.item3')}</NavLink>
                    </li>
                    <li>
                      <NavLink className={(navData) => (navData.isActive ? 'active-footer-item' : '')} to="/productos/tarjetas" onClick={scrollToTop}>{t('footer.soluciones.item4')}</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="column">
                  <h4 className="has-text-weight-bold">
                    <NavLink className={(navData) => (navData.isActive ? 'active-footer-item' : '')} to="/empresa" onClick={scrollToTop}>{t('footer.empresa.header')}</NavLink>
                  </h4>
                  <ul>
                    <li><NavLink className={(navData) => (navData.isActive ? 'active-footer-item' : '')} to="/tarifas" onClick={scrollToTop}>{t('footer.empresa.item1')}</NavLink></li>
                    <li><a href="https://docs.espiralapp.com/">{t('footer.empresa.item2')}</a></li>
                  </ul>
                </div>
                <div className="column">
                  <h4 className="has-text-weight-bold">{t('footer.clientes.header')}</h4>
                  <ul>
                    <li>
                      <a href={t('homepage.banner.button.url')} target='_blank' rel="noopener noreferrer">{t('footer.clientes.item1')}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column">
              <img src="/assets/footer/23LogoPCI-8.png" alt="" />
              <img src="/assets/footer/24LogoBDM-8.png" alt="" />
              <img src="/assets/footer/25LogoCNBV-8.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <footer className="footer has-text-white">
        <div className="container">
          <div className="columns content is-small is-vcentered">
            <div className="column">
              <div className="is-flex">
                <a href="https://www.facebook.com/MyEspiralApp" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/footer/26LogoFace-8.png" alt="" />
                </a>
                <a href="https://www.linkedin.com/company/myespiralapp" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/footer/2LogoLinkedin-8.png" alt="" />
                </a>
              </div>
            </div>
            <div className="column has-text-centered">
              <Trans i18nKey="footer.termsAndPrivacyPolicy">
                <a className="has-text-white" href={t('footer.termsAndPrivacyPolicy.link')} target="_blank" rel="noopener noreferrer" /> <a className="has-text-white" href={t('footer.termsAndPrivacyPolicy.link')} target="_blank" rel="noopener noreferrer"></a>
              </Trans>
            </div>
            <div className="column has-text-right">
              © 2023 EspiralApp. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
Footer.displayName = 'Footer';
