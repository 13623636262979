import React from 'react';
import './Rates.scss';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { Trans, useTranslation } from 'react-i18next';
import Heading from '../../components/Heading/Heading';
import Spacing from '../../components/Spacing/Spacing';

const Rates = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section id="tarifas-banner" className="hero">
        <div className="hero-body mt-6">
          <div className="container mt-4">
            <div className="columns is-centered mb-6">
              <div className="column is-two-thirds mt-6 mb-6">
                <p className="has-text-grey has-text-centered is-size-4">
                  <Trans i18nKey="rates.banner.text" >
                    <span className="has-text-weight-bold has-light-blue" /> <br /> <span className="has-text-weight-bold has-light-blue" />
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="tarifas-main" className="section">
        <div className="container">
          <Spacing margin={{ top: 5 }}>
            <Heading color="light-blue">{t('rates.main.title')}</Heading>
          </Spacing>
          <div className="columns is-centered">
            <div className="column is-one-third">
              <img src="/assets/rates/01Tarifas.png" alt="" />
            </div>
            <div className="column is-one-third">
              <img src="/assets/rates/02Tarifas.png" alt="" />
            </div>
          </div>
          <p className="has-text-centered has-text-weight-bold has-light-blue">{t('rates.main.note')}</p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Rates;
Rates.displayName = 'Rates';