import React from 'react';
import './Dashboard.scss';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import { useTranslation } from 'react-i18next';
import { TextElement, Heading, Spacing } from '../../../components';


const Dashboard = () => {

  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section id="productos-dashboard-banner" className="hero">
        <div className="hero-body mt-6">
          <div className="container mt-4">
            <div className="columns mb-6">
              <div className="column mt-6 mb-6">
                <TextElement content={[t('dashboard.banner.text')]} heading={t('dashboard.banner.title')} subheading={t('dashboard.banner.subtitle')} />
              </div>
              <div className="column pr-0 is-hidden-mobile is-relative">
                <img className="tablero-de-control-img" src="/assets/dashboard/banner-8.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="productos-dashboard-main" className="section">
        <div className="container mt-6 pt-3">
          <Spacing margin={{ top: 6, bottom: 6 }}>
            <Heading weight="bold">{t('dashboard.main.title')}</Heading>
          </Spacing>
          <div className="columns toma-decisiones is-vcentered">
            <div className="column is-half is-hidden-mobile">
              <img src="/assets/dashboard/01tablero-8.png" alt="" />
            </div>
            <div className="column is-two-fifths">
              <TextElement content={[t('dashboard.main.text1')]} subheading={t('dashboard.main.subtitle1')} />
            </div>
          </div>
          <div className="control-total columns is-vcentered">
            <div className="column is-4 is-offset-3 is-size-5-mobile has-text-right has-text-left-mobile">
              <TextElement content={[t('dashboard.main.text2')]} subheading={t('dashboard.main.subtitle2')} />
            </div>
            <div className="column is-hidden-mobile">
              <img className="ml-6" src="/assets/dashboard/02tablero-8.png" alt="" />
            </div>
          </div>
          <div className="columns is-centered descubre-dashboard">
            <div className="column has-text-centered is-two-thirds has-text-left-mobile">
              <TextElement content={[t('dashboard.main.text3')]} subheading={t('dashboard.main.subtitle3')} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Dashboard;
Dashboard.displayName = 'Dashboard';
