import React from 'react';

interface HeadingProps {
  children: React.ReactNode;
  weight?: 'bold' | 'normal';
  color?: 'black' | 'light-blue';
};

const getWeightClassName = (weight: string) => {
  switch (weight) {
    case 'bold':
      return 'has-text-weight-bold';
    case 'normal':
      return 'has-text-weight-normal';
    default:
      return 'has-text-weight-normal';
  }
};

const getColorClassName = (color: string) => {
  switch (color) {
    case 'black':
      return 'has-text-black-ter';
    case 'light-blue':
      return 'has-light-blue';
    default:
      return 'has-text-black-ter';
  }
}

const Heading = ({ children, weight, color }: HeadingProps) => {
  const weightClassName = getWeightClassName(weight || 'normal');
  const colorClassName = getColorClassName(color || 'black');

  return (
    <h2 className={`has-text-centered is-size-4 is-size-5-mobile ${weightClassName} ${colorClassName}`}>{children}</h2>
  );
};

export default Heading;
Heading.displayName = 'Heading';
