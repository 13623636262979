import React from 'react';
import './Tarjetas.scss';
import { useTranslation } from 'react-i18next';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import { TextElement } from '../../../components';

const Tarjetas = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section id="productos-tarjetas-banner" className="hero">
        <div className="hero-body pb-0">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column pb-6">
                <TextElement content={[
                  t('cards.banner.text1'),
                  t('cards.banner.text2')
                ]} heading={t('cards.banner.title')} subheading={t('cards.banner.subtitle')} />
              </div>
              <div className="column is-hidden-mobile">
                <img src="/assets/cards/Banner-8.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="productos-tarjetas-main" className="section mb-6">
        <div className="container">
          <h2 className="has-text-centered is-size-4 has-text-weight-bold has-text-black-ter mb-6">{t('cards.main.title')}</h2>
          <div className="columns is-vcentered">
            <div className="column is-hidden-mobile">
              <img src="/assets/cards/01tarjetas-8.png" alt="" />
            </div>
            <div className="column">
              <TextElement content={[t('cards.main.text1')]} heading={t('cards.main.subtitle1')} />
            </div>
          </div>
          <div className="columns is-vcentered">
            <div className="column has-text-right has-text-left-mobile">
              <TextElement content={[t('cards.main.text2')]} heading={t('cards.main.subtitle2')} />
            </div>
            <div className="column is-hidden-mobile">
              <img src="/assets/cards/02tarjetas-8.png" alt="" />
            </div>
          </div>
          <div className="columns">
            <div className="column"></div>
            <div className="column has-text-centered is-two-thirds has-text-left-mobile">
              <TextElement content={[t('cards.main.text3')]} heading={t('cards.main.subtitle3')} />
            </div>
            <div className="column"></div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Tarjetas;
Tarjetas.displayName = 'Tarjetas';
