import React, { useMemo } from 'react';
import './HomeCarousel.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from '../../components';
import { tns } from 'tiny-slider';
import Heading from '../../components/Heading/Heading';
import Spacing from '../../components/Spacing/Spacing';
import HomeClientes from './HomeClientes';


const HomeCarousel = () => {
  const { t, i18n } = useTranslation();

  const tabs = useMemo(() => [
    {
      label: t('homepage.tabs.item1.button'),
      icon: "📊",
      content: (
        <div className="columns is-vcentered">
          <div className="column has-text-centered-mobile is-one-third-desktop has-text-black-ter">
            <p className="has-text-weight-bold mb-1">{t('homepage.tabs.item1.title')}</p>
            <ul>
              <li>{t('homepage.tabs.item1.item1')}</li>
              <li>{t('homepage.tabs.item1.item2')}</li>
              <li>{t('homepage.tabs.item1.item3')}</li>
              <li>{t('homepage.tabs.item1.item4')}</li>
              <li>{t('homepage.tabs.item1.item5')}</li>
            </ul>
            <div className="columns is-centered my-3">
              <div className="column">
                <ul className="columns is-multiline is-mobile logos-list">
                  <li className="column p-2 is-narrow"><img src="/assets/home/tabs/mpos/logo_Mastercard.svg" alt="" /></li>
                  <li className="column p-2 is-narrow"><img src="/assets/home/tabs/mpos/logo_Visa.svg" alt="" /></li>
                  <li className="column p-2 is-narrow"><img src="/assets/home/tabs/mpos/logo_Samsung.svg" alt="" /></li>
                  <li className="column p-2 is-narrow"><img src="/assets/home/tabs/mpos/logo_Carnet.svg" alt="" /></li>
                  <li className="column p-2 is-narrow"><img src="/assets/home/tabs/mpos/logo_sivale.svg" alt="" /></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column is-hidden-mobile">
            <img src="/assets/home/tabs/mpos/Terminales.png" alt="" />
          </div>
        </div>
      ),
      id: 1
    },
    {
      label: t('homepage.tabs.item2.button'),
      icon: "💳",
      content: (
        <div className="columns is-vcentered">
          <div className="column is-half has-text-black-ter ">
            <p className="mb-3">
              <Trans i18nKey="homepage.tabs.item2.text1">
                <span className="has-text-weight-bold"></span>
              </Trans>
            </p>
            <p className="mb-3">{t('homepage.tabs.item2.text2')}</p>
            <p><span className="has-text-weight-bold">{t('homepage.tabs.item2.text3')}</span></p>
          </div>
          <div className="column is-hidden-mobile">
            <img src="/assets/home/tabs/ecommerce/Ecommerce.png" alt="" />
          </div>
        </div>
      ),
      id: 2
    },
    {
      label: t('homepage.tabs.item3.button'),
      icon: "🛒",
      content: (
        <div className="columns is-vcentered">
          <div className="column is-half has-text-black-ter">
            <p className="mb-3">
              <Trans i18nKey="homepage.tabs.item3.text1">
                <span className="has-text-weight-bold"></span>
              </Trans>
            </p>
            <p className="mb-3">{t('homepage.tabs.item3.text2')}</p>
          </div>
          <div className="column is-hidden-mobile">
            <img src="/assets/home/tabs/tarjetas/Tarjetas.png" alt="" />
          </div>
        </div>
      ),
      id: 3
    }
  ], [t]);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const leftControls = React.useMemo(() => {
    return (
      <div className="carousel-controls is-flex is-flex-direction-column">
        {tabs.map((item, i) => (
          <Button
            key={item.label}
            rounded
            withShadow
            className={`${i !== 0 ? 'mt-5' : ''} ${item.id === selectedTab.id ? "is-primary" : "is-white"}`}
            onClick={() => setSelectedTab(item)}
          >
            {t(`homepage.tabs.item${item.id}.button`)}
          </Button>
        ))}
      </div>
    );
  }, [selectedTab, t, tabs]);


  return (
    <>
      <div className="carousel-window">
        <nav className="carousel-nav is-hidden-desktop">
          <ul>
            {tabs.map((item) => (
              <li
                key={item.label}
                className={item.id === selectedTab.id ? "selected" : ""}
                onClick={() => setSelectedTab(item)}
              >
                {`${item.label}`}
                {item.id === selectedTab.id ? (
                  <motion.div className="underline" layoutId="underline" />
                ) : null}
              </li>
            ))}
          </ul>
        </nav>
        <main className="carousel-content">
          <div className="container">
            <Spacing margin={{ top: 5 }} padding={{ top: 4, bottom: 4, left: 4, right: 4 }}>
              <Heading>{t('homepage.tabs.title')}</Heading>
              <Heading weight="bold" color="light-blue">{t('homepage.tabs.subtitle')}</Heading>
            </Spacing>
            <section className="mpos-tab is-full-mobile is-justify-content-center is-flex p-4">
              <div className="columns is-vcentered is-align-self-center">
                <div className="column is-one-quarter is-hidden-touch">
                  {leftControls}
                </div>
                <div className="column">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={selectedTab ? selectedTab.label : "empty"}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {selectedTab ? selectedTab.content : "😋"}
                    </motion.div>
                  </AnimatePresence>
                </div>
              </div>
            </section>

          </div>
        </main>
      </div>
      <div className="home-final-section">
        <section className="section">
          {/** Usado para forzar el unmount de <HomeClientes /> y volver a iniciar el carrusel */}
          {i18n.resolvedLanguage === 'es' && <HomeClientes />}
          {i18n.resolvedLanguage === 'en' && <HomeClientes />}
        </section>
        <section className="section">
          <div className="container">
            <Heading weight="bold">{t('homepage.certifications.title')}</Heading>
            <div className="columns is-centered mt-6">
              <div className="column is-three-quarters">
                <ul className="columns is-multiline is-mobile partners-list is-centered">
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_mastercard.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_Visa_1.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_banorte.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_PCI.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_Cnbv.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_Bancodemexico.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_Afirme.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_trustwave.svg" alt="" />
                  </li>
                  <li className="column is-narrow">
                    <img src="/assets/home/partners/logo_Mifel.svg" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HomeCarousel;
HomeCarousel.displayName = 'HomeCarousel';
