import React, { useCallback, useEffect, useState } from 'react';
import './Navbar.scss';

import { Trans, useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button } from '../../components';
import { supportedLanguages } from '../../i18n';

const Navbar = () => {
  const [isActive, setisActive] = React.useState(false);
  const toggleIsActive = React.useCallback(() => setisActive(!isActive), [isActive]);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [scroll, setScroll] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  

  return (
    <nav className={`navbar is-fixed-top ${scroll ? 'scrolled' : ''}`} role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" onClick={scrollToTop}>
            <img src="/assets/navbar/01Logo_Header.svg" width="50" height="28" alt="" />
          </Link>

          <a role="button" className={`navbar-burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded={isActive} data-target="navbar-espiral" onClick={toggleIsActive}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbar-espiral" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <span className={`navbar-link has-text-grey is-arrowless ${location.pathname.includes('/productos/') ? 'active-nav-item' : ''}`}>
                {t('homepage.navbar.productos')}
              </span>

              <div className="navbar-dropdown">
                <div className="is-flex">
                  <div className="nav-dropdown-left pt-4 pb-4 pl-5 pr-5">
                    <h3 className="has-text-weight-bold">{t('homepage.navbar.productos.dropdown.title')}</h3>
                    <p className="is-size-7 mt-4 has-text-grey">{t('homepage.navbar.productos.dropdown.text1')}</p>
                    <p className="is-size-7 mt-2 has-text-grey">{t('homepage.navbar.productos.dropdown.text2')}</p>
                    <p className="is-size-7 mt-2 has-text-grey">{t('homepage.navbar.productos.dropdown.text3')}</p>
                  </div>
                  <div className="pt-4 pb-6 pl-4 pr-6">
                    <Link to="/productos/dashboard" className="navbar-item mt-2" onClick={scrollToTop}>
                      <img src="/assets/navbar/Btn_Panel.svg" alt="" />
                      <div className="pl-3">
                        <h4 className="has-text-weight-bold is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link1.text">
                            <br />
                          </Trans>
                        </h4>
                        <p className="has-text-grey is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link1.description">
                            <br />
                          </Trans>
                        </p>
                      </div>
                    </Link>
                    <Link to="/productos/mpos" className="navbar-item mt-2" onClick={scrollToTop}>
                      <img src="/assets/navbar/Btn_Mpos_1.svg" alt="" />
                      <div className="pl-3">
                        <h4 className="has-text-weight-bold is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link2.text">
                            <br />
                          </Trans>
                        </h4>
                        <p className="has-text-grey is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link2.description">
                            <br />
                          </Trans>
                        </p>
                      </div>
                    </Link>
                    <Link to="/productos/ecommerce" className="navbar-item mt-2" onClick={scrollToTop}>
                      <img src="/assets/navbar/Btn_Ecommerce_1.svg" alt="" />
                      <div className="pl-3">
                        <h4 className="has-text-weight-bold is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link3.text">
                            <br />
                          </Trans>
                        </h4>
                        <p className="has-text-grey is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link3.description" >
                            <br />
                          </Trans>
                        </p>
                      </div>
                    </Link>
                    <Link to="/productos/tarjetas" className="navbar-item mt-2" onClick={scrollToTop}>
                      <img src="/assets/navbar/Btn_Tarjetas.svg" alt="" />
                      <div className="pl-3">
                        <h4 className="has-text-weight-bold is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link4.text">
                            <br />
                          </Trans>
                        </h4>
                        <p className="has-text-grey is-size-7">
                          <Trans i18nKey="homepage.navbar.productos.dropdown.link4.description" >
                            <br />
                          </Trans>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <NavLink to="/empresa" className={(navData) => (navData.isActive ? 'active-nav-item navbar-item has-text-grey' : 'navbar-item has-text-grey')} onClick={scrollToTop}>
              {t('homepage.navbar.laEmpresa')}
            </NavLink>

            <NavLink to="/tarifas" className={(navData) => (navData.isActive ? 'active-nav-item navbar-item has-text-grey' : 'navbar-item has-text-grey')} onClick={scrollToTop}>
              {t('homepage.navbar.tarifas')}
            </NavLink>

            <a href="https://docs.espiralapp.com/" className="navbar-item has-text-grey">
              {t('homepage.navbar.desarrolladores')}
            </a>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <Button href="https://dash.espiralapp.com/es/" className="is-small is-primary">
                  {t('homepage.navbar.login')}
                </Button>
                <Button href="https://dash.espiralapp.com/es/register" className="is-small is-primary">
                  {t('homepage.navbar.register')}
                </Button>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                <span className="is-capitalized has-text-primary has-text-weight-semibold is-size-7">{i18n.resolvedLanguage}</span>
                <span className="icon ml-2">
                  <img src="/assets/Icons/004icon_btn_idiomas.svg" alt="" />
                </span>
              </a>

              <div className="navbar-dropdown">
                {supportedLanguages.map((language) => (
                  <a key={`${language.code}_${language.country_code}`} className="navbar-item" onClick={() => i18n.changeLanguage(language.code)}>
                    {language.name}
                  </a>
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
Navbar.displayName = 'Navbar';