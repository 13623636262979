import React from 'react';
import './HomeBanner.scss';
import { Trans, useTranslation } from 'react-i18next';
import Card from '../../components/Card/Card';
import { Button } from '../../components';
import Heading from '../../components/Heading/Heading';
import Spacing from '../../components/Spacing/Spacing';


const HomeBanner = () => {
  const { t } = useTranslation();

  return (
    <div id="home-banner">
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column has-text-centered-mobile pt-6 px-6 is-two-thirds-tablet is-half-desktop">
                <h1 className="banner-main-title is-size-1-widescreen is-size-2 is-size-2-desktop has-light-blue has-text-weight-bold my-6">
                  {t('homepage.banner.title')}
                </h1>
                <p className="has-text-black-ter is-size-5-widescreen is-size-6 mb-5">
                  <Trans i18nKey="homepage.banner.description">
                    <span className="has-text-weight-bold"></span>
                  </Trans>
                </p>
                <Button
                  href={t('homepage.banner.button.url')}
                  target='_blank'
                  className="home-banner-main-cta is-medium is-primary pl-6 pr-6 py-5 mt-5"
                  rounded>
                  {t('homepage.banner.button')}
                </Button>
              </div>
              <div className="column">
                <div className="is-relative home-banner-images is-hidden-mobile">
                  <img className="is-relative banner-dash" src="/assets/home/04Banner_Dash-8.png" alt="" />
                  <img className="banner-mpos" src="/assets/home/05Banner_Mobile-8.png" alt="" />
                  <img className="banner-card" src="/assets/home/06Banner_CC-8.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <Spacing margin={{ bottom: 6 }}>
            <Heading weight="bold" color="light-blue">{t('homepage.banner.carousel.title')}</Heading>
            <Heading>
              <Trans i18nKey="homepage.banner.carousel.subtitle" >
                <br />
              </Trans>
            </Heading>
          </Spacing>

          <div className="columns is-multiline cards-columns is-mobile">
            <div className="column is-half-mobile">
              <Card
                image='/assets/home/07Icon_Panel.svg'
                title={t('homepage.banner.carousel.item1.title')}
                isExpandalbe
                contents={[
                  t('homepage.banner.carousel.item1.text1'),
                  t('homepage.banner.carousel.item1.text2')
                ]}
              />
            </div>
            <div className="column is-half-mobile">
              <Card
                image='/assets/home/08Icon_Mpos_1.svg'
                title={t('homepage.banner.carousel.item2.title')}
                isExpandalbe
                contents={[
                  t('homepage.banner.carousel.item2.text1'),
                  t('homepage.banner.carousel.item2.text2')
                ]}
              />
            </div>
            <div className="column is-half-mobile">
              <Card
                image='/assets/home/09Icon_Ecommerce_1.svg'
                title={t('homepage.banner.carousel.item3.title')}
                isExpandalbe
                contents={[
                  t('homepage.banner.carousel.item3.text')
                ]}
              />
            </div>
            <div className="column is-half-mobile">
              <Card
                image='/assets/home/10Icon_Links.svg'
                title={t('homepage.banner.carousel.item4.title')}
                isExpandalbe
                contents={[
                  t('homepage.banner.carousel.item4.text')
                ]}
              />
            </div>
            <div className="column is-half-mobile">
              <Card
                image='/assets/home/11Icon_CC.svg'
                title={t('homepage.banner.carousel.item5.title')}
                isExpandalbe
                contents={[
                  t('homepage.banner.carousel.item5.text1'),
                  t('homepage.banner.carousel.item5.text2')
                ]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeBanner;
HomeBanner.displayName = 'HomeBanner';
