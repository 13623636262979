import React from 'react';
import './Mpos.scss';

import { Trans, useTranslation } from 'react-i18next';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import Heading from '../../../components/Heading/Heading';
import Spacing from '../../../components/Spacing/Spacing';
import TextElement from '../../../components/TextElement/TextElement';

const Mpos = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section id="productos-mpos-banner" className="hero">
        <div className="hero-body pb-0">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column pb-6">
                <TextElement
                  content={[
                    <Trans i18nKey="mpos.banner.text">
                      <span className="has-text-weight-bold"></span> <span className="has-text-weight-bold"></span>
                    </Trans>
                  ]}
                  heading={t('mpos.banner.title')}
                  subheading={t('mpos.banner.subtitle')}
                  theme="purple" />
              </div>
              <div className="column is-hidden-mobile">
                <img src="/assets/mpos/01Banner_Girl-8.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="productos-mpos-main" className="section">
        <div className="container">
          <div className="terminales">
            <Spacing margin={{ top: 6, bottom: 5 }}>
              <Heading weight="bold">{t('mpos.main.title')}</Heading>
            </Spacing>
            <div className="columns is-vcentered velocidad-y-comodidad">
              <div className="column is-half has-text-centered is-hidden-mobile">
                <img src="/assets/mpos/02app-8.png" alt="" />
              </div>
              <div className="column is-two-fifths mb-5">
                <TextElement content={[t('mpos.main.text1')]} subheading={t('mpos.main.subtitle1')} theme="purple" />
              </div>
            </div>
          </div>
          <div className="columns is-vcentered conexion-tiempo-real">
            <div className="column has-text-right has-text-left-mobile">
              <TextElement content={[t('mpos.main.text2')]} subheading={t('mpos.main.subtitle2')} theme="purple" />
            </div>
            <div className="column has-text-centered is-hidden-mobile">
              <img src="/assets/mpos/03app-8.png" alt="" />
            </div>
          </div>
          <Heading weight="bold">{t('mpos.main.newTerminals.title')}</Heading>
          <div className="columns cobra-mas">
            <div className="column">
              <div className="is-flex is-flex-direction-column is-align-items-end">
                <img src="/assets/mpos/04spral01-8.png" alt="" />
              </div>
              <TextElement content={[t('mpos.main.text3')]} subheading={t('mpos.main.subtitle3')} theme="purple" />
            </div>
            <div className="column has-text-right has-text-left-mobile">
              <div className="is-flex is-flex-direction-column is-align-items-start">
                <img src="/assets/mpos/05spral02-8.png" alt="" />
              </div>
              <TextElement content={[t('mpos.main.text4')]} subheading={t('mpos.main.subtitle4')} theme="purple" />
            </div>
          </div>
          <ul className="columns is-centered is-multiline is-mobile logos-list mt-6">
            <li className="column p-5 is-narrow"><img src="/assets/home/tabs/mpos/logo_Mastercard.svg" alt="" /></li>
            <li className="column p-5 is-narrow"><img src="/assets/home/tabs/mpos/logo_Visa.svg" alt="" /></li>
            <li className="column p-5 is-narrow"><img src="/assets/home/tabs/mpos/logo_Samsung.svg" alt="" /></li>
            <li className="column p-5 is-narrow"><img src="/assets/home/tabs/mpos/logo_Carnet.svg" alt="" /></li>
            <li className="column p-5 is-narrow"><img src="/assets/home/tabs/mpos/logo_sivale.svg" alt="" /></li>
          </ul>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Mpos;
Mpos.displayName = 'Mpos';
