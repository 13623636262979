import React from 'react';
import './Company.scss';
import { useTranslation } from 'react-i18next';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Heading from '../../components/Heading/Heading';
import Spacing from '../../components/Spacing/Spacing';
import { TextElement } from '../../components';

const Company = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section id="company-banner" className="hero">
        <div className="hero-body mt-6">
          <div className="container mt-4">
            <div className="columns mb-6">
              <div className="column mt-6 mb-6">
                <img className="espiral-banner-logo mb-5" src="/assets/company/000Logotipo.png" alt="" />
                <TextElement content={[
                  t('company.banner.text'),
                  <span className="has-text-weight-bold is-block has-light-blue mt-5">{t('company.banner.text2')}</span>
                ]} />
              </div>
              <div className="column pr-0 is-hidden-mobile is-relative">
                <img className="company-img" src="/assets/company/01Comercio.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="company-main" className="section">
        <div className="columns is-centered">
          <div className="column has-text-centered is-two-thirds mt-6 has-text-left-mobile pl-6 pr-6">
            <TextElement content={[t('company.main.text1')]} subheading={t('company.main.title')} />
          </div>
        </div>
        <div className="container mt-6 pt-3">
          <div className="company-vertical-line">
            <div className="columns is-centered is-vcentered">
              <div className="column pl-6 pr-6 pt-0 is-5 has-text-right has-text-left-mobile">
                <TextElement content={[t('company.main.text2'), <span className="is-block mt-5">{t('company.main.text3')}</span>]} />
              </div>
              <div className="column pl-6 is-5 is-hidden-mobile">
                <img src="/assets/company/02Respaldo.png" alt="" />
              </div>
            </div>
            <div className="columns is-centered is-vcentered mt-3 mb-6 pb-6">
              <div className="column pr-6 is-5 is-hidden-mobile">
                <img src="/assets/company/03Confianza.png" alt="" />
              </div>
              <div className="column is-5 pt-0 pr-6 pl-6 ">
                <TextElement content={[t('company.main.text4')]} />
              </div>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column has-text-centered is-two-thirds">
              <Spacing margin={{ top: 6, bottom: 6 }}>
                <Heading weight="bold" color="light-blue">{t('company.main.join.title')}</Heading>
              </Spacing>
              <img src="/assets/company/04Socios.png" alt="" />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Company;
Company.displayName = "Company";