import React from 'react';

import { useTranslation } from 'react-i18next';
import Footer from '../sections/footer/Footer';
import Navbar from '../sections/navbar/Navbar';
import HomeBanner from '../sections/home/HomeBanner';
import HomeCarousel from '../sections/home/HomeCarousel';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <HomeBanner />
      <HomeCarousel />
      <Footer />
    </>
  );
};

export default Home;
Home.displayName = 'Home';
