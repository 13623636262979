import React from 'react';
import './Ecommerce.scss';

import { Trans, useTranslation } from 'react-i18next';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import Card from '../../../components/Card/Card';
import TextElement from '../../../components/TextElement/TextElement';

const Ecommerce = () => {

  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section id="productos-ecommerce-banner" className="hero">
        <div className="hero-body pb-0">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column pb-6">
                <TextElement content={[
                  <Trans i18nKey="ecommerce.banner.text">
                    <span className="has-text-weight-bold"></span> <span className="has-text-weight-bold"></span>
                  </Trans>
                ]} heading={t('ecommerce.banner.title')} subheading={t('ecommerce.banner.subtitle')} theme="green" />
              </div>
              <div className="column is-hidden-mobile">
                <img src="/assets/ecommerce/01Banner_Boy.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="productos-ecommerce-main" className="section">
        <div className="container">
          <div className="que-esperar">
            <h2 className="has-text-centered is-size-4 has-text-weight-bold has-text-black-ter mb-6">{t('ecommerce.main.title')}</h2>
            <div className="columns is-vcentered">
              <div className="column is-three-fifths is-hidden-mobile">
                <img src="/assets/ecommerce/02Tablero-8.png" alt="" />
              </div>
              <div className="column">
                <TextElement content={[t('ecommerce.main.text1')]} heading={t('ecommerce.main.subtitle1')} theme="green" />
              </div>
            </div>
          </div>
          <div className="columns is-vcentered que-esperar-2">
            <div className="column has-text-right has-text-left-mobile">
              <TextElement content={[t('ecommerce.main.text2')]} heading={t('ecommerce.main.subtitle2')} theme="green" />
            </div>
            <div className="column is-hidden-mobile">
              <img src="/assets/ecommerce/03Tablero-8.png" alt="" />
            </div>
          </div>
          <div className="solucion-que-se-adapte">
            <h2 className="has-text-centered is-size-4 has-text-weight-bold has-text-black-ter mb-6">{t('ecommerce.main.carousel.title')}</h2>
            <div className="columns is-centered">
              <div className="column is-three-quarters">
                <div className="columns">
                  <div className="column">
                    <Card image='/assets/ecommerce/04Referencia.svg' contents={[t('ecommerce.main.carousel.item1.text')]} title={t('ecommerce.main.carousel.item1.title')} />
                  </div>
                  <div className="column">
                    <Card image='/assets/ecommerce/05Links.svg' contents={[t('ecommerce.main.carousel.item2.text')]} title={t('ecommerce.main.carousel.item2.title')} />
                  </div>
                  <div className="column">
                    <Card image='/assets/ecommerce/06Devs.svg' contents={[t('ecommerce.main.carousel.item3.text')]} title={t('ecommerce.main.carousel.item3.title')} />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-centered my-6">
              <div className="column is-four-fifths">
                <ul className="columns is-multiline is-mobile logos-list is-centered">
                  <li className="column is-narrow"><img src="/assets/ecommerce/07logo_Banorte.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/08logo_Elsol.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/09logo_chedraui.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/10logo_ahorro.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/11logo_woolwoth.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/12logo_Telecomm.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/13logo_Aurrera.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/14logo_yastas.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/15logo_superama.svg" alt="" /></li>
                  <li className="column is-narrow"><img src="/assets/ecommerce/16logo_Seven.svg" alt="" /></li>
                </ul>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <TextElement content={[t('ecommerce.main.text3')]} heading={t('ecommerce.main.subtitle3')} theme="green" />
              </div>
              <div className="column  has-text-right has-text-left-mobile">
              <TextElement content={[t('ecommerce.main.text4')]} heading={t('ecommerce.main.subtitle4')} theme="green" />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
};

export default Ecommerce;
Ecommerce.displayName = 'Ecommerce';
